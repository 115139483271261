import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from "@angular/forms";
import { DialogService } from "../../../shared/components/default/dialog/dialog.service";
import { BACK_BUTTON } from "../../../shared/default-variables/actions";



@Component({
    selector: 'app-errors-dialog',
    templateUrl: './errors-dialog.component.html',
    styleUrls: ['./errors-dialog.component.scss']
})
export class ErrorsDialogComponent  {
    form = this.fb.group({});

    icon = "info";
    title = 'errorDialog.title';

    reset = BACK_BUTTON(() => this.dialogService.close(), {
		text: 'generic.actions.ok',
		tooltip: 'generic.actions.ok'
	});

    data: { message: string };

    constructor(private fb: UntypedFormBuilder,
                private dialogService: DialogService) {
		this.data = this.dialogService.dialogAssets.configurations.data;


	}

}
