export const LOAD_ENV = async () => {

	const resp = await fetch('assets/config/config.json')
		.then((response) => response.json()) as {apiUrl: string};

	const env2 = {
		api: resp.apiUrl,
	};

	Object.assign(environment, env2);
	return env2;
};

export const environment = {
	development: false,
	production: true,
	sandbox: false,
	staging: false,
	api: "",
	appVersion: require('../../package.json').version,
};
