import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpResponse } from "@angular/common/http";

import { FormButtonComponent } from "./form-button.component";
import { FormButtonLoading } from "../../../../models/form-button-loading";
import { Auxiliary } from "../../../../helpers/auxiliary";

@Injectable({
    providedIn: 'root'
})
export class FormButtonService {
    private lastClickedButton: FormButtonComponent|null = null;
    private listOfClickedButton: FormButtonLoading[] = [];

    constructor(){}

    setLastClickedButton(button: FormButtonComponent|null, reset?: boolean): void{
        this.lastClickedButton = reset ? null : button;
    }

    addLoading(httpRequest: HttpRequest<unknown>): void{
        const url: string = httpRequest.url;

        if(url && this.lastClickedButton){
            this.lastClickedButton.loading = true;

            this.lastClickedButton.changeDetector.detectChanges();

            this.listOfClickedButton.push({ button: this.lastClickedButton, url });
        }
    }

    removeLoading(response: HttpResponse<any>|HttpErrorResponse): void{
        const formButtonLoadingFromRequest =
            this.listOfClickedButton
                .find((formButtonLoading) =>
                    Auxiliary.removeUrlParameters(formButtonLoading.url) === Auxiliary.removeUrlParameters(response.url || '')
                );

        if(formButtonLoadingFromRequest){
            formButtonLoadingFromRequest.button.loading = false;
			formButtonLoadingFromRequest.button.changeDetector.markForCheck();

            this.listOfClickedButton.splice(this.listOfClickedButton.indexOf(formButtonLoadingFromRequest), 1);
        }
    }
}
