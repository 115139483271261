<div class="icon-error">
    <mat-icon>{{icon}}</mat-icon>

</div>
<app-form [form]="form"
          [isDialog]="true"
          [principalTitle]="title"
          [resetDialog]="reset"
          class="error">

    <p class="error__text">
        {{this.data.message}}

    </p>


</app-form>
