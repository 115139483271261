export const ACTIONS_TRANSLATE = {
    actions: {
        create: "Criar",
        add: "Adicionar",
        import: "Importar",
        save: "Salvar",
		back: "Voltar",
		print: "Imprimir",
        continue: "Continuar",
        copy: "Copiar",
		ok: "Ok"
    }
};
