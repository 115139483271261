import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from "./filter/filter.pipe";
import { SafeUrlPipe } from "./safe-url/safe-url.pipe";
import { CurrencyFormatPipe } from "./currency-format/currency-format.pipe";
import { FormPipeModule } from "./form/form-pipe.module";
import { CallPipe } from "./call/call.pipe";
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { NotNullPipe } from './not-null/not-null.pipe';
import { ToStringPipe } from "./to-string.pipe";
import {RootTranslatePipe} from "./utils/root-translate.pipe";


@NgModule({
    declarations: [
        FilterPipe,
        SafeUrlPipe,
        CurrencyFormatPipe,
        CallPipe,
        SafeHtmlPipe,
        NotNullPipe,
        ToStringPipe,
		RootTranslatePipe
    ],
    imports: [
        CommonModule,
        FormPipeModule
    ],
    exports: [
        FilterPipe,
        SafeUrlPipe,
        CurrencyFormatPipe,
        CallPipe,
        SafeHtmlPipe,
        NotNullPipe,
        ToStringPipe
    ]
})
export class PipesModule {
}
