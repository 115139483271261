import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";

import { TranslateModule } from "@ngx-translate/core";
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { QuillModule } from "ngx-quill";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from "./core/angular-material/angular-material.module";
import { Translate } from "./shared/helpers/translate";
import { NoResultsModule } from "./shared/components/default/no-results/no-results.module";
import { PipesModule } from "./shared/pipes/pipes.module";
import { GlobalLoadingModule } from "./shared/components/default/global-loading/global-loading.module";
import { ErrorsModule } from "./core/errors/errors.module";
import { AppRequestsModule } from "./app-requests.module";
import { MenuModule } from "./core/menu/menu.module";
import { productionReducer as appReducer } from './shared/store/reducers';
import { ALL_EFFECTS } from "./shared/store/effects/all-effects";
import { MandatoryDialogsModule } from "./shared/services/mandatory-dialogs/mandatory-dialogs.module";
import { EnvService } from "./env.service";


registerLocaleData(localePt, 'pt');

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		TranslateModule.forRoot(Translate.configurations),
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		BrowserAnimationsModule,
		AngularMaterialModule,
		TranslateModule,
		NoResultsModule,
		PipesModule,
		StoreModule.forRoot({ app: appReducer }),
		GlobalLoadingModule,
		HttpClientModule,
		ErrorsModule,
		AppRequestsModule,
		StoreRouterConnectingModule.forRoot(),
		EffectsModule.forRoot(ALL_EFFECTS),
		MandatoryDialogsModule,
		MenuModule,
		QuillModule.forRoot()

	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: (envConfigService: EnvService) => () => envConfigService.load(),
			deps: [EnvService],
			multi: true
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
