import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorsHandlerService } from "./errors-handler.service";
import { ErrorsDialogComponent } from "./dialog/errors-dialog.component";
import { FormModule } from "../../shared/components/default/form/form.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [
		ErrorsDialogComponent
	],
	imports: [
		CommonModule,
		FormModule,
		MatIconModule
	],
    providers: [
        {
            provide: ErrorHandler,
            useClass: ErrorsHandlerService
        }
    ]
})
export class ErrorsModule{}
